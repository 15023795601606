import { UserRole } from 'enums';

export const ROUTE_PATH = {
  PUBLIC: {
    LOGIN: '/login',
    LOGIN_TWO_FA: '/login/2fa',
    ACCEPT_INVITE: '/invite/:token',
    SESSION_EXPIRED: '/session-expired',
    ACCOUNT_DISABLED: '/account-disabled',
  },
  ADMIN: {
    MAIN: '/admin',
    WALLETS: '/admin/wallets',
    SETTINGS: '/admin/settings',
    USER_DETAILS: '/admin/user/:id',
    TRADERS: '/admin/traders',
    TRADER_DETAILS: '/admin/traders/:id',
    CASHBACK: '/admin/cashback',
    PAYIN_CASHBACK: '/admin/cashback/payin',
    PAYOUT_CASHBACK: '/admin/cashback/payout',
    USERS: '/admin/users',
    SHOPS: '/admin/shops',
    REQUISITES: '/admin/requisites',
    PAYIN_REQUISITES: '/admin/requisites/payin',
    PAYOUT_REQUISITES: '/admin/requisites/payout',
    PAYIN_REQUISITES_DETAILS: '/admin/requisites/payin/:id',
    PAYIN_REQUISITES_GROUP_DETAILS: '/admin/requisites/payin/group/:id',
    SHOP_DETAILS: '/admin/shops/:id',
    FUNDS_REQUESTS: '/admin/funds-requests',
    INTERNAL_TRANSFERS: '/admin/internal_transfers',
    ASSET_BALANCE_HISTORY: '/admin/asset-balance-history',
    ORDERS: '/admin/orders',
    PAYIN_ORDERS: '/admin/orders/payin',
    PAYOUT_ORDERS: '/admin/orders/payout',
    GROUPS: '/admin/groups',
    GROUP_DETAILS: '/admin/group/:id',
    CURRENCIES: '/admin/currencies',
    CURRENCY_EXCHANGE_DETAILS: '/admin/currency-exchange/:id',
    DISTRIBUTION: '/admin/distribution',
    BANKS: '/admin/banks',
    TRADE_METHODS: '/admin/trade-methods',
    TRADE_METHOD_PARSINGS: '/admin/trade-methods/:id/parsings',
    TRADE_METHOD_AUTOMATION_SOURCES:
      '/admin/trade-methods/:id/automation-sources',
    TRADE_METHOD_FILES_VALIDATION: '/admin/trade-methods/:id/files-validation',
    PARSING_METHODS_DETAILS: '/admin/parsing-methods/:id',
    ANOMALIES: '/admin/anomalies',
    ORDER_AUTOMATION: '/admin/order-automation',
    ARCHIVE: '/admin/archive',
    WEBHOOKS: '/admin/webhooks',
    STATISTICS: '/admin/statistics',
    AUTOMATION_SOURCES: '/admin/automation-sources',
    TRANSACTIONS: '/admin/transactions',
    DEPOSIT_TRANSACTIONS: '/admin/transactions/deposit',
    WITHDRAWAL_TRANSACTIONS: '/admin/transactions/withdrawal',
    SCI_DOMAINS: '/admin/sci-domains',
    AUDIT: '/admin/audit',
    BALANCE_RECONCILIATION: '/admin/balance-reconciliation',
    P2P_PROVIDERS: '/admin/p2p-providers',
    P2P_PROVIDERS_DETAILS: '/admin/p2p-providers/:id',
    CURRENCY_EXCHANGE: '/admin/currency-exchange',
    BLOCKED_CUSTOMERS: '/admin/blocked-customers',
    CONFIGS: '/admin/configs',
    EXECUTION_METRICS: '/admin/execution-metrics',
  },
  OPERATOR: {
    MAIN: '/operator',
    SETTINGS: '/operator/settings',
    ARCHIVE: '/operator/archive',
    ASSETS: '/operator/assets',
    ORDERS: '/operator/orders',
    ORDER_AUTOMATION: '/operator/order-automation',
    PAYIN_ORDERS: '/operator/orders/payin',
    PAYOUT_ORDERS: '/operator/orders/payout',
    FUNDS_REQUESTS: '/operator/funds-requests',
    REQUISITES: '/operator/requisites',
    PAYIN_REQUISITES: '/operator/requisites/payin',
    PAYOUT_REQUISITES: '/operator/requisites/payout',
    TRADERS: '/operator/traders',
  },
  TECH_OPERATOR: {
    MAIN: '/tech-operator',
    SETTINGS: '/tech-operator/settings',
    USER_DETAILS: '/tech-operator/user/:id',
    TRADERS: '/tech-operator/traders',
    TRADER_DETAILS: '/tech-operator/traders/:id',
    CASHBACK: '/tech-operator/cashback',
    PAYIN_CASHBACK: '/tech-operator/cashback/payin',
    PAYOUT_CASHBACK: '/tech-operator/cashback/payout',
    USERS: '/tech-operator/users',
    SHOPS: '/tech-operator/shops',
    REQUISITES: '/tech-operator/requisites',
    PAYIN_REQUISITES: '/tech-operator/requisites/payin',
    PAYOUT_REQUISITES: '/tech-operator/requisites/payout',
    PAYIN_REQUISITES_DETAILS: '/tech-operator/requisites/payin/:id',
    PAYIN_REQUISITES_GROUP_DETAILS: '/tech-operator/requisites/payin/group/:id',
    SHOP_DETAILS: '/tech-operator/shops/:id',
    FUNDS_REQUESTS: '/tech-operator/funds-requests',
    INTERNAL_TRANSFERS: '/tech-operator/internal_transfers',
    ASSET_BALANCE_HISTORY: '/tech-operator/asset-balance-history',
    ORDERS: '/tech-operator/orders',
    PAYIN_ORDERS: '/tech-operator/orders/payin',
    PAYOUT_ORDERS: '/tech-operator/orders/payout',
    GROUPS: '/tech-operator/groups',
    GROUP_DETAILS: '/tech-operator/group/:id',
    CURRENCIES: '/tech-operator/currencies',
    CURRENCY_EXCHANGE_DETAILS: '/tech-operator/currency-exchange/:id',
    DISTRIBUTION: '/tech-operator/distribution',
    BANKS: '/tech-operator/banks',
    TRADE_METHODS: '/tech-operator/trade-methods',
    TRADE_METHOD_PARSINGS: '/tech-operator/trade-methods/:id/parsings',
    TRADE_METHOD_AUTOMATION_SOURCES:
      '/tech-operator/trade-methods/:id/automation-sources',
    TRADE_METHOD_FILES_VALIDATION:
      '/tech-operator/trade-methods/:id/files-validation',
    PARSING_METHODS_DETAILS: '/tech-operator/parsing-methods/:id',
    ANOMALIES: '/tech-operator/anomalies',
    ORDER_AUTOMATION: '/tech-operator/order-automation',
    ARCHIVE: '/tech-operator/archive',
    WEBHOOKS: '/tech-operator/webhooks',
    STATISTICS: '/tech-operator/statistics',
    AUTOMATION_SOURCES: '/tech-operator/automation-sources',
    TRANSACTIONS: '/tech-operator/transactions',
    DEPOSIT_TRANSACTIONS: '/tech-operator/transactions/deposit',
    WITHDRAWAL_TRANSACTIONS: '/tech-operator/transactions/withdrawal',
    SCI_DOMAINS: '/tech-operator/sci-domains',
    AUDIT: '/tech-operator/audit',
    P2P_PROVIDERS: '/tech-operator/p2p-providers',
    P2P_PROVIDERS_DETAILS: '/tech-operator/p2p-providers/:id',
    CURRENCY_EXCHANGE: '/tech-operator/currency-exchange',
    BLOCKED_CUSTOMERS: '/tech-operator/blocked-customers',
    CONFIGS: '/tech-operator/configs',
    EXECUTION_METRICS: '/tech-operator/execution-metrics',
  },
  TRADER: {
    MAIN: '/trader',
    FUNDS_REQUESTS: '/trader/funds-requests',
    INTERNAL_TRANSFERS: '/trader/internal-transfers',
    BALANCE_HISTORY: '/trader/asset-balance-history',
    SETTINGS: '/trader/settings',
    REQUISITES: '/trader/requisites',
    PAYIN_REQUISITES: '/trader/requisites/payin',
    PAYIN_REQUISITES_DETAILS: '/trader/requisites/payin/:id',
    PAYIN_REQUISITES_GROUP_DETAILS: '/trader/requisites/payin/group/:id',
    PAYOUT_REQUISITES: '/trader/requisites/payout',
    PAYOUT_REQUISITES_DETAILS: '/trader/requisites/payout/:id',
    PAYOUT_REQUISITES_AUTOMATION_DETAILS:
      '/trader/requisites/payout/automation/:id',
    ORDERS: '/trader/orders',
    PAYIN_ORDERS: '/trader/orders/payin',
    PAYOUT_ORDERS: '/trader/orders/payout',
    REFERRAL_SYSTEM: '/trader/referral-system',
    ORDER_AUTOMATION: '/trader/order-automation',
    STATISTICS: '/trader/statistics',
    REQUISITES_TELEGRAM_AUTOMATION_DETAILS:
      '/trader/requisites-payin-telegram-automation/:id',
    CURRENCY_EXCHANGE: '/trader/currency-exchange',
  },
  MERCHANT: {
    MAIN: '/merchant',
    FUNDS_REQUESTS: '/merchant/funds-requests',
    INTERNAL_TRANSFERS: '/merchant/internal-transfers',
    BALANCE_HISTORY: '/merchant/asset-balance-history',
    SETTINGS: '/merchant/settings',
    SHOPS: '/merchant/shops',
    SHOP_DETAILS: '/merchant/shops/:id',
    SHOP_ASSETS: '/merchant/shop-assets',
    ORDERS: '/merchant/orders',
    PAYIN_ORDERS: '/merchant/orders/payin',
    PAYOUT_ORDERS: '/merchant/orders/payout',
    WEBHOOKS: '/merchant/webhooks',
    STATISTICS: '/merchant/statistics',
    CURRENCY_EXCHANGE: '/merchant/currency-exchange',
  },
};

export const DEFAULT_ROLE_PATH = {
  [UserRole.Admin]: ROUTE_PATH.ADMIN.MAIN,
  [UserRole.Operator]: ROUTE_PATH.OPERATOR.MAIN,
  [UserRole.Trader]: ROUTE_PATH.TRADER.MAIN,
  [UserRole.TechOperator]: ROUTE_PATH.TECH_OPERATOR.MAIN,
  [UserRole.Merchant]: ROUTE_PATH.MERCHANT.MAIN,
};
