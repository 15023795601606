import { find, map, values } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdditionalOrderColumn,
  CallbackUrlStatus,
  FilterDefinitionType,
  OrdersTab,
  PayoutOrderAutomationStatus,
} from 'enums';
import { useCurrencies, usePayoutOrdersContext, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, OrderFilters, PayoutOrderFilters } from 'types';
import { formatUtils, formUtils, orderUtils, requisitesUtils } from 'utils';

import { Orders } from '../../Orders';

type Props = {
  tab: OrdersTab;
  additionalFilters?: FilterDefinition<OrderFilters>[];
  additionalColumns?: AdditionalOrderColumn[];
};

export const ManageOrdersList: React.FC<Props> = ({
  tab,
  additionalFilters = [],
  additionalColumns = [],
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });
  const { paymentTypes, banks, p2pProviders } = useUserContext();
  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();
  const { shops, merchants, traders } = usePayoutOrdersContext();

  const automationStatusOptions = useMemo(
    () =>
      map(values(PayoutOrderAutomationStatus), (status) => ({
        value: status,
        label: orderUtils.getPayoutAutomationStatusLabel(status),
      })),
    [],
  );

  const filters: FilterDefinition<PayoutOrderFilters>[] = useMemo(
    () => [
      {
        label: tCommon('filters.customer'),
        name: 'customerId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tCommon('filters.trader'),
        name: 'traderId',
        type: FilterDefinitionType.User,
        users: traders,
        getDisplayName: (traderId: string) =>
          find(traders, { id: traderId })?.name,
        hidden: additionalColumns.includes(AdditionalOrderColumn.P2PProvider),
      },
      {
        label: tCommon('filters.merchant'),
        name: 'merchantId',
        type: FilterDefinitionType.User,
        users: merchants,
        getDisplayName: (merchantId: string) =>
          find(merchants, { id: merchantId })?.name,
      },
      {
        label: tCommon('filters.shop'),
        name: 'shopId',
        type: FilterDefinitionType.Shop,
        shops,
        getDisplayName: (shopId: string) => find(shops, { id: shopId })?.name,
      },
      {
        label: tCommon('filters.external_order_id'),
        name: 'externalOrderId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tCommon('filters.callback_url_status'),
        name: 'callbackUrlStatus',
        type: FilterDefinitionType.Enum,
        enum: CallbackUrlStatus,
        getDisplayName: orderUtils.getCallbackUrlStatusLabel,
      },
      {
        label: tCommon('filters.payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (value: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: value })!,
          ),
      },
      {
        label: tCommon('filters.bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (value: string) => find(banks, { id: value })?.name,
      },
      {
        label: tCommon('filters.fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
      {
        label: tCommon('filters.amount'),
        name: 'amount',
        type: FilterDefinitionType.Numeric,
      },
      {
        label: tCommon('filters.p2p_provider'),
        name: 'p2pProviderId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(p2pProviders),
        getDisplayName: (value: string) => {
          const provider = find(p2pProviders, { id: value });
          return formatUtils.formatName(provider);
        },
        hidden: additionalColumns.includes(AdditionalOrderColumn.Trader),
      },
      {
        label: tCommon('filters.requisites_automation_id'),
        name: 'requisitesAutomationId',
        type: FilterDefinitionType.Text,
        format: 'uuid',
      },
      {
        label: tCommon('filters.automation_id'),
        name: 'orderAutomationId',
        type: FilterDefinitionType.Text,
        format: 'uuid',
      },
      {
        label: tCommon('filters.automation_status'),
        name: 'orderAutomationStatus',
        type: FilterDefinitionType.Select,
        options: automationStatusOptions,
        getDisplayName: orderUtils.getPayoutAutomationStatusLabel,
      },
    ],
    [
      banks,
      fiatCurrenciesOptions,
      getFiatCurrencyCode,
      paymentTypes,
      merchants,
      shops,
      traders,
      p2pProviders,
      additionalColumns,
      tCommon,
      automationStatusOptions,
    ],
  );

  return (
    <Orders
      tab={tab}
      additionalFilters={[...filters, ...additionalFilters]}
      additionalColumns={[
        AdditionalOrderColumn.CustomerContacts,
        AdditionalOrderColumn.Merchant,
        AdditionalOrderColumn.AutomationStatus,
        ...additionalColumns,
      ]}
    />
  );
};
