import { Tab } from '@mui/material';
import { FC, Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { tradersApi } from 'api';
import { PageHeader, QueryTabs, QueryTabPanel, DataWrapper } from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { PayoutRequisitesTabs, QueryKey } from 'enums';
import { useQueryTab, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { PayoutRequisitesAutomationList } from '../RequisitesAutomation';
import { PayoutRequisitesList } from '../RequisitesList';

export const PayoutRequisitesPage: FC = () => {
  const { tab } = useQueryTab<PayoutRequisitesTabs>();

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites',
  });

  const { isTrader, isAdminOrTechOperator } = useUser();

  const tradeInfoQueryResult = useQuery(QueryKey.MyInfo, tradersApi.getMyInfo, {
    enabled: isTrader,
  });

  const canSeeAutomation = useMemo(
    () =>
      isAdminOrTechOperator ||
      (isTrader &&
        tradeInfoQueryResult.data?.payoutRequisitesAutomationEnabled),
    [
      isAdminOrTechOperator,
      isTrader,
      tradeInfoQueryResult.data?.payoutRequisitesAutomationEnabled,
    ],
  );

  const navigate = useNavigate();

  const handleCreateRequisites = useCallback(() => {
    navigate(
      generatePath(ROUTE_PATH.TRADER.PAYOUT_REQUISITES_DETAILS, {
        id: NEW_ID,
      }),
    );
  }, [navigate]);

  const handleCreateAutomation = useCallback(() => {
    navigate(
      generatePath(ROUTE_PATH.TRADER.PAYOUT_REQUISITES_AUTOMATION_DETAILS, {
        id: NEW_ID,
      }),
    );
  }, [navigate]);

  const handleCreateClick = useCallback(() => {
    if (tab === PayoutRequisitesTabs.Requisites) {
      handleCreateRequisites();
    } else if (tab === PayoutRequisitesTabs.Automation) {
      handleCreateAutomation();
    }
  }, [tab, handleCreateRequisites, handleCreateAutomation]);

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        divider={false}
        {...(isTrader && {
          rightContentButton: { onClick: handleCreateClick },
        })}
      />
      <DataWrapper
        isLoading={
          tradeInfoQueryResult.isLoading || tradeInfoQueryResult.isRefetching
        }
      >
        <Fragment>
          <QueryTabs tabsEnum={PayoutRequisitesTabs}>
            <Tab
              value={PayoutRequisitesTabs.Requisites}
              label={t('tabs.requisites')}
            ></Tab>
            {canSeeAutomation && (
              <Tab
                value={PayoutRequisitesTabs.Automation}
                label={t('tabs.automation')}
              ></Tab>
            )}
          </QueryTabs>
          <QueryTabPanel value={PayoutRequisitesTabs.Requisites}>
            <PayoutRequisitesList />
          </QueryTabPanel>
          {canSeeAutomation && (
            <QueryTabPanel value={PayoutRequisitesTabs.Automation}>
              <PayoutRequisitesAutomationList />
            </QueryTabPanel>
          )}
        </Fragment>
      </DataWrapper>
    </Fragment>
  );
};
