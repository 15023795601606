import { useFormikContext } from 'formik';
import { filter, find, uniqBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControls, FormikSelect } from 'components';
import { TranslationNamespace } from 'i18n';
import { AppConfig, AppConfigSchema } from 'types';
import { appConfigUtils, p2pProviderUtils } from 'utils';

import { ConfigValueField } from '../..//ConfigValueField';

type Props = {
  data: Partial<AppConfig>;
  schemas: AppConfigSchema[];
};

export const PayinP2PProvidersConfigForm: React.FC<Props> = ({
  data,
  schemas,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.configs',
  });

  const formik = useFormikContext<any>();

  const p2pProviderTypeOptions = useMemo(
    () =>
      uniqBy(schemas, 'p2pProviderType').map((schema) => ({
        label: p2pProviderUtils.getTypeLabel(schema.p2pProviderType!),
        value: schema.p2pProviderType!,
      })),
    [schemas],
  );

  const keyOptions = useMemo(
    () =>
      filter(
        schemas,
        (schema) => schema.p2pProviderType === formik.values.p2pProviderType,
      ).map((schema) => ({
        label: appConfigUtils.getKeyLabel(schema.key),
        value: schema.key,
      })),
    [formik.values, schemas],
  );

  const keySchema = useMemo(
    () =>
      find(
        schemas,
        (schema) =>
          schema.p2pProviderType === formik.values.p2pProviderType &&
          schema.key === formik.values.key,
      ),
    [formik.values, schemas],
  );

  const handleP2PProviderTypeChange = useCallback(() => {
    formik.setFieldValue('key', '');
    formik.setFieldTouched('key', false, true);
  }, [formik]);

  return (
    <FormControls>
      <FormikSelect
        label={t('fields.provider')}
        name="p2pProviderType"
        options={p2pProviderTypeOptions}
        disabled={!!data?.id}
        required
        noneOption
        onChange={handleP2PProviderTypeChange}
      />
      <FormikSelect
        label={t('fields.key')}
        name="key"
        options={keyOptions}
        disabled={!!data?.id || !formik.values.p2pProviderType}
        required
        noneOption
      />
      {keySchema && <ConfigValueField schema={keySchema} data={data} />}
    </FormControls>
  );
};
