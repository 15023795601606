import { AxiosError } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import { values } from 'lodash';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { appConfigsApi } from 'api';
import {
  CloseFormikDialogResult,
  DataWrapper,
  Dialog,
  DialogProps,
} from 'components';
import { AppConfigArea, AppConfigKey, P2PProviderType, QueryKey } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AppConfig } from 'types';
import { formatUtils, validationUtils } from 'utils';

import { PayinP2PProvidersConfigForm } from './PayinP2PProvidersConfigForm';

type Props = DialogProps<Partial<AppConfig>>;

type Values = {
  key: AppConfigKey;
  p2pProviderType?: P2PProviderType;
  jsonObjectValue?: string;
  jsonArrayValue?: string;
};

export const ConfigDetailsDialog: React.FC<Props> = ({
  data,
  open,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.configs',
  });
  const { t: tCommon } = useTranslation();

  const queryClient = useQueryClient();

  const isNew = useMemo(() => !data?.id, [data]);

  const schemasQueryResult = useQuery(
    QueryKey.ConfigsSchemas,
    appConfigsApi.getAreaSchemas(data?.area!),
    {
      enabled: !!data && isNew,
    },
  );

  const configSchemaQueryResult = useQuery(
    [QueryKey.ConfigsSchemas, data?.id],
    appConfigsApi.getConfigSchema(data?.id!),
    {
      enabled: !isNew,
    },
  );

  const schemas = useMemo(
    () =>
      isNew
        ? schemasQueryResult.data || []
        : configSchemaQueryResult?.data
        ? [configSchemaQueryResult?.data]
        : [],
    [configSchemaQueryResult?.data, isNew, schemasQueryResult.data],
  );

  const title = useMemo(
    () =>
      isNew ? t('details_dialog.create_title') : t('details_dialog.edit_title'),
    [t, isNew],
  );

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries(QueryKey.Configs);
  }, [queryClient]);

  const createMutation = useMutation(appConfigsApi.create, {
    notifierType: 'save',
    onSuccess: invalidateQuery,
  });

  const updateMutation = useMutation(appConfigsApi.update, {
    notifierType: 'save',
    onSuccess: invalidateQuery,
  });

  const defaultInitialValues = useMemo(
    () => ({
      key: '' as AppConfigKey,
      p2pProviderType: '' as P2PProviderType,
      jsonObjectValue: '',
      jsonArrayValue: '',
    }),
    [],
  );

  const [initialValues, setInitialValues] =
    useState<Values>(defaultInitialValues);

  const validationSchema: Yup.ObjectSchema<Partial<Values>> = useMemo(
    () =>
      Yup.object().shape({
        key: Yup.string()
          .oneOf(values(AppConfigKey))
          .required(tCommon('errors.required')),
        p2pProviderType: Yup.string()
          .oneOf(values(P2PProviderType))
          .required(tCommon('errors.required')),
        jsonObjectValue: Yup.string(),
        jsonArrayValue: Yup.string(),
      }),
    [tCommon],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok || !result.data?.values) {
        result?.data?.formikHelpers.resetForm();
        setInitialValues({} as Values);
        return onClose({ ok: false });
      }
      const options = {
        onSuccess: () => {
          result?.data?.formikHelpers.resetForm();
          setInitialValues({} as Values);
          onClose({ ok: true });
        },
        onError: (error: AxiosError) => {
          result.data?.formikHelpers.setErrors(
            validationUtils.getFormErrors(error),
          );
        },
      };
      let jsonObjectValue = null;
      let jsonArrayValue = null;
      if (result.data.values?.jsonObjectValue) {
        const parsedJson = formatUtils.parseJsonText({
          text: result.data.values.jsonObjectValue,
        });
        if (parsedJson.isJson) {
          jsonObjectValue = JSON.parse(parsedJson.parsedText);
        }
      }
      if (result.data.values?.jsonArrayValue) {
        const parsedJson = formatUtils.parseJsonText({
          text: result.data.values.jsonArrayValue,
        });
        if (parsedJson.isJson) {
          jsonArrayValue = JSON.parse(parsedJson.parsedText);
        }
      }
      if (isNew) {
        createMutation.mutate(
          {
            ...result.data.values,
            jsonObjectValue,
            jsonArrayValue,
            area: data?.area,
          },
          options,
        );
      } else {
        updateMutation.mutate(
          {
            id: data?.id!,
            data: {
              jsonObjectValue,
              jsonArrayValue,
            },
          },
          options,
        );
      }
    },
    [createMutation, updateMutation, data, onClose, isNew],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      handleClose({ ok: true, data: { values, formikHelpers } });
    },
    [handleClose],
  );

  useEffect(() => {
    if (open) {
      setInitialValues(
        data?.id
          ? {
              key: data.key!,
              p2pProviderType: data.p2pProviderType,
              jsonObjectValue: JSON.stringify(data.jsonObjectValue, null, 2),
              jsonArrayValue: JSON.stringify(data.jsonArrayValue, null, 2),
            }
          : defaultInitialValues,
      );
    }
  }, [data, defaultInitialValues, open]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      validateOnChange
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={title}
          data={{ values: formik.values, formikHelpers: formik }}
          okDisabled={!formik.isValid}
          onClose={handleClose}
        >
          <DataWrapper
            queryResult={isNew ? schemasQueryResult : configSchemaQueryResult}
          >
            <Fragment>
              {data?.area === AppConfigArea.PayinP2PProvider && (
                <PayinP2PProvidersConfigForm data={data} schemas={schemas} />
              )}
            </Fragment>
          </DataWrapper>
        </Dialog>
      )}
    </Formik>
  );
};
