import {
  CreditCardOff as CreditCardOffIcon,
  CreditCard as CreditCardIcon,
} from '@mui/icons-material';
import { ChipProps } from '@mui/material';
import React from 'react';

import { StatusLabel } from 'components';
import { formatUtils } from 'utils';

type Props = {
  status: 'active' | 'inactive';
} & Partial<ChipProps>;

export const EnabledStatusLabel: React.FC<Props> = ({ status, ...rest }) => (
  <StatusLabel
    label={formatUtils.formatEnabled(status === 'active')}
    status={status}
    icon={status === 'active' ? <CreditCardIcon /> : <CreditCardOffIcon />}
    {...rest}
  />
);
