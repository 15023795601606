import moment from 'moment';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import {
  blockedCustomersApi,
  blockedCustomersHistoryApi,
  merchantsApi,
  shopsApi,
} from 'api';
import {
  CloseDialogResult,
  CrudPage,
  DataGridColumnDefinition,
  dataGridColumns,
  Dialog,
  UserInfo,
} from 'components';
import { QueryKey } from 'enums';
import {
  useMutation,
  usePartialQuery,
  useQueryFilters,
  useShopsQuery,
  useUser,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  BlockedCustomer,
  BlockedCustomerHistory,
  BlockedCustomersFilters,
} from 'types';
import { blockedCustomersUtils, formatUtils } from 'utils';

type Props = {
  archived?: boolean;
};

export const BlockedCustomersList: React.FC<Props> = ({ archived }) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.blocked_customers',
  });
  const { t: tCommon } = useTranslation();
  const { role } = useUser();
  const queryClient = useQueryClient();

  useQueryFilters<BlockedCustomersFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
  });

  const queryFn = useMemo(() => {
    if (archived) {
      return blockedCustomersHistoryApi.getAllPaginatedAsRole(role);
    }
    return blockedCustomersApi.getAllPaginatedAsRole(role);
  }, [archived, role]);

  const queryResult = usePartialQuery(QueryKey.BlockedCustomers, queryFn);

  const [confirmRemoveDialogProps, setConfirmRemoveDialogProps] = useState<{
    open: boolean;
    data?: BlockedCustomer;
  }>({
    open: false,
  });

  const queryResultMerchants = useQuery(
    QueryKey.Merchants,
    merchantsApi.getAllAsRole(role),
  );

  const queryResultShops = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );

  const { mutate: removeBlockedCustomer, isLoading: isRemoveLoading } =
    useMutation(blockedCustomersApi.removeAsRole(role), {
      onSuccess: () => {
        setConfirmRemoveDialogProps({ open: false });
        queryClient.invalidateQueries(QueryKey.BlockedCustomers);
      },
    });

  const handleCloseRemoveDialog = useCallback(
    ({ ok, data }: CloseDialogResult<BlockedCustomer>) => {
      if (ok && data) {
        removeBlockedCustomer(data.id);
      } else {
        setConfirmRemoveDialogProps({ open: false });
      }
    },
    [removeBlockedCustomer],
  );

  const handleRemove = useCallback((data: BlockedCustomer) => {
    setConfirmRemoveDialogProps({ open: true, data });
  }, []);

  const columns = useMemo(
    (): DataGridColumnDefinition<
      BlockedCustomer | BlockedCustomerHistory
    >[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.shop'),
        valueGetter: (item) => (
          <Fragment>
            <div>{item.merchant.user.name}</div>
            <div>{formatUtils.formatName(item.shop)}</div>
          </Fragment>
        ),
      },
      {
        header: t('fields.customer'),
        valueGetter: (item) => <UserInfo id={item.customerId} />,
      },
      {
        header: t('fields.ip'),
        valueKey: 'customerIp',
      },
      {
        header: t('fields.fingerprint'),
        valueKey: 'customerFingerprint',
      },
      {
        header: t('fields.rules'),
        valueGetter: (item) => (
          <Fragment>
            <div>{`${t('fields.duration')}: ${item.duration} ${tCommon(
              'suffixes.minutes',
            )}`}</div>
            <div>{`${t('fields.count')}: ${item.count}`}</div>
            <div>{`${t('fields.period')}: ${item.period} ${tCommon(
              'suffixes.minutes',
            )}`}</div>
          </Fragment>
        ),
      },
      {
        header: t('fields.until_at'),
        valueKey: 'untilAt',
        valueFormatter: formatUtils.formatDate,
        hidden: archived,
      },
      {
        header: t('fields.block_at'),
        valueGetter: (item) => (item as BlockedCustomerHistory).blockAt,
        valueFormatter: formatUtils.formatDate,
        hidden: !archived,
      },
      {
        header: t('fields.processed_at'),
        valueGetter: (item) => (item as BlockedCustomerHistory).processedAt,
        valueFormatter: formatUtils.formatDate,
        hidden: !archived,
      },
      {
        header: t('fields.processor'),
        valueGetter: (item) => (item as BlockedCustomerHistory).processor.name,
        hidden: !archived,
      },
      dataGridColumns.getActionsColumn({
        handleRemove,
        hidden: archived,
      }),
    ],
    [t, tCommon, handleRemove, archived],
  );

  const filtersDefinitions = useMemo(
    () =>
      blockedCustomersUtils.getCommonFilters(
        queryResultMerchants.data,
        queryResultShops.data,
      ),
    [queryResultMerchants.data, queryResultShops.data],
  );

  return (
    <Fragment>
      <CrudPage
        filters={{ filtersDefinitions, withCommon: true }}
        table={{ queryResult, columns, paginated: true }}
      >
        <Dialog
          title={t('remove_dialog.title')}
          onClose={handleCloseRemoveDialog}
          disabled={isRemoveLoading}
          {...confirmRemoveDialogProps}
        >
          <Fragment>
            <div className="tw-mb-4">{t('remove_dialog.description')}</div>
            {confirmRemoveDialogProps.data && (
              <Fragment>
                <div className="tw-mb-2">
                  <div>{confirmRemoveDialogProps.data.merchant.user.name}</div>
                  <div>
                    {formatUtils.formatName(confirmRemoveDialogProps.data.shop)}
                  </div>
                </div>
                <div>
                  {confirmRemoveDialogProps.data.customerId && (
                    <div>
                      {`${t('fields.customer')}: `}
                      <UserInfo id={confirmRemoveDialogProps.data.customerId} />
                    </div>
                  )}
                  {confirmRemoveDialogProps.data.customerIp && (
                    <div>{`${t('fields.ip')}: ${
                      confirmRemoveDialogProps.data.customerIp
                    }`}</div>
                  )}
                  {confirmRemoveDialogProps.data.customerIp && (
                    <div>{`${t('fields.fingerprint')}: ${
                      confirmRemoveDialogProps.data.customerFingerprint
                    }`}</div>
                  )}
                  <div>{`${t('fields.until_at')}: ${formatUtils.formatDate(
                    confirmRemoveDialogProps.data.untilAt,
                  )}`}</div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </Dialog>
      </CrudPage>
    </Fragment>
  );
};
