import axios from 'axios';

import { env } from 'config';
import {
  PayoutRequisitesAutomation,
  PayoutRequisitesAutomationAccountInfo,
  PayoutRequisitesAutomationConfig,
  QueryParams,
} from 'types';
import { apiUtils } from 'utils';

import { createCrudApi } from './crud.api';

const path = '/payout-requisites-automation';
const url = `${env.apiUrl}${path}`;

const getConfig = async (
  params?: Partial<QueryParams>,
): Promise<PayoutRequisitesAutomationConfig[]> =>
  (await apiUtils.getWithQueryParams(`${url}/config`, params)).data;

const getAccountInfo = async (
  id: string,
): Promise<PayoutRequisitesAutomationAccountInfo> =>
  (await axios.get(`${url}/${id}/account-info`)).data;

const getOrderAutomations = async (
  orderId: string,
): Promise<PayoutRequisitesAutomation[]> =>
  (await axios.get(`${url}/order-automations/${orderId}`)).data;

export const payoutRequisitesAutomationApi = {
  ...createCrudApi<PayoutRequisitesAutomation>(path),
  getConfig,
  getAccountInfo,
  getOrderAutomations,
};
