import { Tab } from '@mui/material';
import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { AppConfigArea } from 'enums';
import { useQueryTab } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AppConfig } from 'types';

import { ConfigDetailsDialog } from './ConfigDetailsDialog';
import { PayinP2PProvidersTab } from './PayinP2PProvidersTab';

enum Tabs {
  PayinP2PProviders = 'payin_p2p_providers',
}

export const ConfigsPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.configs',
  });

  const { tab } = useQueryTab();

  const [createDialogProps, setCreateDialogProps] = useState<{
    open: boolean;
    data?: Partial<AppConfig>;
  }>({ open: false });

  const handleCreateClick = useCallback(() => {
    if (tab === Tabs.PayinP2PProviders) {
      setCreateDialogProps({
        open: true,
        data: { area: AppConfigArea.PayinP2PProvider },
      });
    }
  }, [tab]);

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        divider={false}
        rightContentButton={{ onClick: handleCreateClick }}
      />
      <QueryTabs tabsEnum={Tabs}>
        <Tab
          value={Tabs.PayinP2PProviders}
          label={t('tabs.payin_p2p_providers')}
        />
      </QueryTabs>
      <QueryTabPanel value={Tabs.PayinP2PProviders}>
        <PayinP2PProvidersTab />
      </QueryTabPanel>
      <ConfigDetailsDialog
        {...createDialogProps}
        onClose={() => setCreateDialogProps({ open: false })}
      />
    </Fragment>
  );
};
