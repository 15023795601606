import { Sync as SyncIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';
import { PayoutRequisitesAutomationAccountInfo } from 'types';
import { formatUtils } from 'utils';

type Props = {
  accountInfo: PayoutRequisitesAutomationAccountInfo;
  disabled?: boolean;
  getAccountInfo: () => void;
};

export const PayoutAutomationAccountInfoDetails: React.FC<Props> = ({
  accountInfo,
  disabled,
  getAccountInfo,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.payout_requisites_automation.account_info',
  });

  return (
    <div className="tw-flex tw-items-center">
      {accountInfo && (
        <Fragment>
          {accountInfo.balance && (
            <div className="tw-mr-1">
              <div>{`${t('balance')}: ${formatUtils.formatMoney(
                accountInfo.balance,
              )}`}</div>
            </div>
          )}
        </Fragment>
      )}
      <IconButton color="primary" disabled={disabled} onClick={getAccountInfo}>
        <SyncIcon />
      </IconButton>
    </div>
  );
};
