import { Typography } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikTextField } from 'components';
import { P2PProviderType } from 'enums';
import { TranslationNamespace } from 'i18n';

type Props = {
  path: string;
  providerType?: P2PProviderType;
};

export const P2PProviderCredentials: React.FC<Props> = ({
  path,
  providerType,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });

  return (
    <Fragment>
      <Typography>{t('fields.credentials')}</Typography>
      {providerType === P2PProviderType.InternalLike && (
        <Fragment>
          <FormikTextField
            label={t('fields.api_key')}
            name={`${path}.apiKey`}
          />
          <FormikTextField
            label={t('fields.signature_key')}
            name={`${path}.signatureKey`}
          />
        </Fragment>
      )}
      {providerType === P2PProviderType.Trust2Pay && (
        <Fragment>
          <FormikTextField
            label={t('fields.api_secret')}
            name={`${path}.apiSecret`}
          />
          <FormikTextField
            label={t('fields.password')}
            name={`${path}.password`}
          />
        </Fragment>
      )}
      {providerType === P2PProviderType.X2X && (
        <Fragment>
          <FormikTextField
            label={t('fields.api_secret')}
            name={`${path}.apiSecret`}
          />
          <FormikTextField
            label={t('fields.password')}
            name={`${path}.password`}
          />
        </Fragment>
      )}
      {providerType === P2PProviderType.Payscrow && (
        <Fragment>
          <FormikTextField
            label={t('fields.api_key')}
            name={`${path}.apiKey`}
          />
          <FormikTextField
            label={t('fields.api_secret')}
            name={`${path}.apiSecret`}
          />
        </Fragment>
      )}
      {providerType === P2PProviderType.Namba && (
        <Fragment>
          <FormikTextField
            label={t('fields.api_token')}
            name={`${path}.apiToken`}
          />
        </Fragment>
      )}
      {providerType === P2PProviderType.Paycraft && (
        <Fragment>
          <FormikTextField
            label={t('fields.api_private')}
            name={`${path}.apiPrivate`}
          />
          <FormikTextField
            label={t('fields.api_public')}
            name={`${path}.apiPublic`}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
