import { Tab } from '@mui/material';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { tradeMethodsApi } from 'api';
import {
  PageHeader,
  Dialog,
  DataWrapper,
  QueryTabPanel,
  QueryTabs,
} from 'components';
import { ParsingMethodType, QueryKey } from 'enums';
import {
  ParsingMethodsDetailsForm,
  ParsingMethodsList,
  ParsingMethodsProvider,
} from 'features';
import { useQueryTab, useUserContextUtils } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { ParsingMethod } from 'types';

export const TradeMethodParsingsPage = () => {
  const { id: tradeMethodId = '' } = useParams<{ id: string }>();

  // TODO: rename tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.parsing_methods',
  });
  const { getTradeMethodLabel } = useUserContextUtils();

  const [isDetailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [selectedParsingMethod, setSelectedParsingMethod] =
    React.useState<ParsingMethod | null>(null);

  const { tab } = useQueryTab<ParsingMethodType>();

  const tradeMethodQuery = useQuery(
    [QueryKey.TradeMethods, tradeMethodId],
    () => tradeMethodsApi.getOne(tradeMethodId!),
  );

  const subtitle = useMemo(
    () => getTradeMethodLabel(tradeMethodId),
    [getTradeMethodLabel, tradeMethodId],
  );

  const onEdit = useCallback((parsingMethod: ParsingMethod) => {
    setDetailsDialogOpen(true);
    setSelectedParsingMethod(parsingMethod);
  }, []);

  const onCreate = useCallback(() => {
    setDetailsDialogOpen(true);
    setSelectedParsingMethod(null);
  }, []);

  const onClose = useCallback(() => {
    setDetailsDialogOpen(false);
    setSelectedParsingMethod(null);
  }, []);

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        subtitle={subtitle}
        divider={false}
        rightContentButton={{
          onClick: onCreate,
        }}
      />
      <QueryTabs tabsEnum={ParsingMethodType}>
        <Tab value={ParsingMethodType.Payin} label={t('types.payin')} />
        <Tab value={ParsingMethodType.Payout} label={t('types.payout')} />
        <Tab value={ParsingMethodType.Technical} label={t('types.technical')} />
        <Tab value={ParsingMethodType.Spam} label={t('types.spam')} />
        <Tab value={ParsingMethodType.Blocked} label={t('types.blocked')} />
      </QueryTabs>
      <QueryTabPanel value={tab}>
        <DataWrapper queryResult={tradeMethodQuery}>
          <ParsingMethodsProvider
            tradeMethod={tradeMethodQuery.data}
            type={tab}
          >
            <ParsingMethodsList onEdit={onEdit} />
            <Dialog
              title={
                selectedParsingMethod
                  ? t('details_form.title.edit')
                  : t('details_form.title.create')
              }
              open={isDetailsDialogOpen}
              onClose={onClose}
              maxWidth="sm"
              modal
            >
              <ParsingMethodsDetailsForm
                selectedParsingMethod={selectedParsingMethod}
                tab={tab}
                onSubmit={onClose}
              />
            </Dialog>
          </ParsingMethodsProvider>
        </DataWrapper>
      </QueryTabPanel>
    </Fragment>
  );
};
