import { map } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { appConfigsApi } from 'api';
import {
  CrudPage,
  CrudTableActionType,
  DataGridColumnDefinition,
  dataGridColumns,
  ExpandedJson,
} from 'components';
import {
  AppConfigArea,
  AppConfigKey,
  FilterDefinitionType,
  P2PProviderType,
  QueryKey,
} from 'enums';
import { useMutation, usePartialQuery } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AppConfig, FilterDefinition } from 'types';
import { appConfigUtils, p2pProviderUtils } from 'utils';

import { ConfigDetailsDialog } from '../ConfigDetailsDialog';

type AppConfigFilters = {
  key: AppConfigKey;
  p2pProviderType: P2PProviderType;
};

export const PayinP2PProvidersTab: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.configs',
  });

  const queryResult = usePartialQuery(
    QueryKey.Configs,
    appConfigsApi.getForAreaPaginated(AppConfigArea.PayinP2PProvider),
  );

  const { mutate: remove } = useMutation(appConfigsApi.remove, {
    onSuccess: () => {
      queryResult.refetch();
    },
    notifierType: 'remove',
  });

  const columns = useMemo(
    (): DataGridColumnDefinition<AppConfig>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.provider'),
        valueKey: 'p2pProviderType',
        valueFormatter: p2pProviderUtils.getTypeLabel,
      },
      {
        header: t('fields.key'),
        valueKey: 'key',
        valueFormatter: appConfigUtils.getKeyLabel,
      },
      {
        header: t('fields.value'),
        valueGetter: (item) => {
          if (item.jsonObjectValue) {
            return <ExpandedJson text={JSON.stringify(item.jsonObjectValue)} />;
          } else if (item.jsonArrayValue) {
            return <ExpandedJson text={JSON.stringify(item.jsonArrayValue)} />;
          }
        },
      },
    ],
    [t],
  );

  const p2pProviderTypeOptions = useMemo(
    () =>
      map(P2PProviderType, (type) => ({
        label: p2pProviderUtils.getTypeLabel(type),
        value: type,
      })),
    [],
  );

  const filtersDefinitions: FilterDefinition<AppConfigFilters>[] = useMemo(
    () => [
      {
        label: t('fields.provider'),
        name: 'p2pProviderType',
        type: FilterDefinitionType.Select,
        options: p2pProviderTypeOptions,
        getDisplayName: p2pProviderUtils.getTypeLabel,
      },
      {
        label: t('fields.key'),
        name: 'key',
        type: FilterDefinitionType.Enum,
        enum: [AppConfigKey.BanksMap, AppConfigKey.PaymentTypeMap],
        getDisplayName: appConfigUtils.getKeyLabel,
      },
    ],
    [p2pProviderTypeOptions, t],
  );

  return (
    <CrudPage
      table={{
        queryResult,
        columns,
        paginated: true,
        actions: [
          {
            type: CrudTableActionType.Details,
            renderDialog: ConfigDetailsDialog,
          },
          {
            type: CrudTableActionType.Remove,
            onRemove: (item, { close }) =>
              remove(item.id, { onSuccess: close }),
          },
        ],
      }}
      filters={{ filtersDefinitions }}
    />
  );
};
