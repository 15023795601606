import { Typography } from '@mui/material';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { map } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { payoutRequisitesAutomationApi } from 'api';
import { FormikSelect } from 'components';
import { QueryKey } from 'enums';
import { PayoutAutomationAccountInfoDetails } from 'features/payout-requisites';
import { useMutation, usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutOrder } from 'types';

type Props = {
  order: PayoutOrder;
};

export const ConfirmPaymentAutomation: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.confirm_payment_dialog',
  });

  const formik = useFormikContext<{ automationId: string }>();
  const prevAutomationId = usePrevious(formik.values.automationId);

  const queryResultAutomations = useQuery(QueryKey.PayoutOrderAutomations, () =>
    payoutRequisitesAutomationApi.getOrderAutomations(order.id),
  );

  const accountInfoMutation = useMutation(
    payoutRequisitesAutomationApi.getAccountInfo,
    {
      notifierType: 'execute',
    },
  );

  const automationOptions = useMemo(
    () =>
      map(queryResultAutomations.data, (automation) => ({
        label: automation.name,
        value: automation.id,
      })),
    [queryResultAutomations],
  );

  useEffect(() => {
    if (
      accountInfoMutation.data &&
      prevAutomationId !== formik.values.automationId
    ) {
      accountInfoMutation.reset();
    }
  }, [accountInfoMutation, prevAutomationId, formik.values.automationId]);

  return (
    <div>
      <FormikSelect
        name="automationId"
        label={t('automation')}
        options={automationOptions}
        fullWidth
      />
      {formik.values.automationId && (
        <div
          className={cx('tw-mt-2', {
            'tw-flex tw-items-center': !accountInfoMutation.data,
          })}
        >
          <Typography sx={{ mr: 1 }} variant="subtitle1">{`${t(
            'account_info',
          )}:`}</Typography>
          <PayoutAutomationAccountInfoDetails
            accountInfo={accountInfoMutation.data!}
            disabled={accountInfoMutation.isLoading}
            getAccountInfo={() => {
              accountInfoMutation.mutate(formik.values.automationId);
            }}
          />
        </div>
      )}
    </div>
  );
};
