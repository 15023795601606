import { CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import { filter, find, isEmpty } from 'lodash';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MutateOptions, useQuery, useQueryClient } from 'react-query';

import { payoutRequisitesAutomationApi, usersApi } from 'api';
import {
  DataGridColumnDefinition,
  dataGridColumns,
  RequisitesInfo,
  CrudPage,
  CopyTextId,
  CrudTableActionType,
  StatusToggle,
} from 'components';
import { ROUTE_PATH } from 'constants/routes';
import {
  FilterDefinitionType,
  PayoutRequisitesAutomationStatus,
  PayoutRequisitesAutomationType,
  QueryKey,
} from 'enums';
import {
  useCurrencies,
  useMutation,
  usePartialQuery,
  useUser,
  useUserContext,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  FilterDefinition,
  PayoutRequisitesAutomation,
  PayoutRequisitesAutomationAccountInfo,
} from 'types';
import {
  formatUtils,
  formUtils,
  payoutRequisitesAutomationUtils,
  requisitesUtils,
} from 'utils';

import { PayoutAutomationAccountInfoDetails } from './AccountInfoDetails';

type Filters = Partial<{
  id: string;
  type: PayoutRequisitesAutomationType;
  userId: string;
  status: PayoutRequisitesAutomationStatus;
  bankId: string;
  paymentTypeId: string;
  fiatCurrencyId: string;
}>;

type Props = {
  archived?: boolean;
};

export const PayoutRequisitesAutomationList: FC<Props> = ({ archived }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.payout_requisites_automation',
  });
  const { t: tCommon } = useTranslation();
  const queryClient = useQueryClient();

  const { role, isTrader, isManager } = useUser();
  const { banks, paymentTypes } = useUserContext();

  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();

  const detailsPath = useMemo(() => {
    if (isTrader) {
      return ROUTE_PATH.TRADER.PAYOUT_REQUISITES_AUTOMATION_DETAILS;
    }
    return '';
  }, [isTrader]);

  const [accountInfos, setAccountInfos] = useState<{
    [id: string]: PayoutRequisitesAutomationAccountInfo;
  }>({});
  const [accountInfoLoading, setAccountInfoLoading] = useState<string[]>([]);

  const { mutate: getAccountInfo } = useMutation(
    payoutRequisitesAutomationApi.getAccountInfo,
    {
      onMutate: (itemId: string) => {
        setAccountInfoLoading((data) => [...data, itemId]);
      },
      onSuccess: (data) => {
        setAccountInfos((resources) => ({
          ...resources,
          [data.id]: data,
        }));
      },
      onSettled: (data, error, itemId: string) => {
        setAccountInfoLoading((data) => filter(data, (id) => id !== itemId));
      },
      notifierType: 'execute',
    },
  );

  const queryResult = usePartialQuery(
    QueryKey.PayoutRequisitesAutomation,
    archived
      ? payoutRequisitesAutomationApi.getAllArchivePaginated
      : payoutRequisitesAutomationApi.getAllPaginated,
  );

  const queryResultTraders = useQuery(
    QueryKey.UsersTraders,
    usersApi.getAllTraders,
    {
      enabled: isManager,
    },
  );

  const { mutate: archive } = useMutation(
    payoutRequisitesAutomationApi.archive,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.PayoutRequisitesAutomation);
      },
      notifierType: 'remove',
    },
  );

  const { mutate: updateStatus } = useMutation<
    PayoutRequisitesAutomation,
    AxiosError,
    { id: string; data: Pick<PayoutRequisitesAutomation, 'status'> }
  >(payoutRequisitesAutomationApi.updateAsRole(role), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.PayoutRequisitesAutomation);
    },
    notifierType: 'execute',
  });

  const handleStatusChange = useCallback(
    (
      id: string,
      status: PayoutRequisitesAutomationStatus,
      options?: MutateOptions<any, any, any, any>,
    ) =>
      updateStatus(
        {
          id,
          data: { status },
        },
        options,
      ),
    [updateStatus],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<PayoutRequisitesAutomation>[] => [
      {
        header: t('table.columns.status'),
        valueGetter: (item) => (
          <StatusToggle
            status={item.status}
            activeStatus={PayoutRequisitesAutomationStatus.Active}
            inactiveStatus={PayoutRequisitesAutomationStatus.Inactive}
            disabled={!isTrader || item.requisites.archived}
            updateStatus={(status) => handleStatusChange(item.id, status)}
          />
        ),
      },
      dataGridColumns.getIdColumn(),
      {
        header: t('table.columns.trader'),
        valueGetter: (item) => item.user?.name,
        hidden: !isManager,
      },
      {
        header: t('table.columns.type'),
        valueKey: 'type',
        valueFormatter: payoutRequisitesAutomationUtils.getTypeLabel,
      },
      {
        header: t('table.columns.name'),
        valueGetter: (item) => item.name,
      },
      {
        header: t('table.columns.requisites'),
        valueGetter: (item) => (
          <div>
            <RequisitesInfo
              paymentTypeId={item.requisites?.paymentTypeId}
              bankId={item.requisites?.bankId}
              fiatCurrencyId={item.requisites?.fiatCurrencyId}
            />
            {formatUtils.getArchivedLabel(item.requisites)}
            <div className="tw-mt-1">
              <CopyTextId id={item.requisitesId} />
            </div>
          </div>
        ),
      },
      {
        header: t('table.columns.wallet'),
        valueGetter: (item) => item.wallet,
      },
      {
        header: t('table.columns.account_info'),
        valueGetter: (item) => {
          if (accountInfoLoading.includes(item.id)) {
            return <CircularProgress size={20} />;
          }

          return (
            <PayoutAutomationAccountInfoDetails
              accountInfo={accountInfos[item.id]}
              disabled={!isEmpty(accountInfoLoading)}
              getAccountInfo={() => getAccountInfo(item.id)}
            />
          );
        },
      },
      {
        header: t('table.columns.limit_sum'),
        valueFormatter: formatUtils.formatMoney,
        valueGetter: (item) => formatUtils.formatMoney(item.limits?.limitSum),
      },
      {
        header: t('table.columns.limit_count'),
        valueGetter: (item) => formatUtils.formatMoney(item.limits?.limitCount),
      },
    ],
    [
      t,
      isManager,
      isTrader,
      accountInfoLoading,
      accountInfos,
      handleStatusChange,
      getAccountInfo,
    ],
  );

  const filtersDefinitions: FilterDefinition<Filters>[] = useMemo(
    () => [
      {
        label: tCommon('filters.id'),
        name: 'id',
        type: FilterDefinitionType.Text,
        format: 'uuid',
      },
      {
        label: t('filters.trader'),
        name: 'userId',
        type: FilterDefinitionType.User,
        users: queryResultTraders.data,
        getDisplayName: (id: string) =>
          find(queryResultTraders.data, { id })?.name,
        hidden: !isManager,
      },
      {
        label: t('filters.status'),
        name: 'status',
        type: FilterDefinitionType.Enum,
        enum: PayoutRequisitesAutomationStatus,
        getDisplayName: (value) =>
          formatUtils.formatEnabled(
            value === PayoutRequisitesAutomationStatus.Active,
          ),
      },
      {
        label: t('filters.type'),
        name: 'type',
        type: FilterDefinitionType.Enum,
        enum: PayoutRequisitesAutomationType,
        getDisplayName: payoutRequisitesAutomationUtils.getTypeLabel,
      },
      {
        label: t('filters.bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (bankId: string) => find(banks, { id: bankId })?.name,
      },
      {
        label: t('filters.payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (paymentType: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: paymentType })!,
          ),
      },
      {
        label: t('filters.fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
    ],
    [
      banks,
      fiatCurrenciesOptions,
      getFiatCurrencyCode,
      isManager,
      paymentTypes,
      queryResultTraders.data,
      t,
      tCommon,
    ],
  );

  return (
    <Fragment>
      <CrudPage
        filters={{ filtersDefinitions }}
        table={{
          queryResult,
          columns,
          paginated: true,
          hideActions: !isTrader,
          actions: [
            {
              type: CrudTableActionType.Details,
              path: detailsPath,
            },
            {
              type: CrudTableActionType.Remove,
              onRemove: (item, { close }) =>
                archive(item.id, { onSuccess: close }),
            },
          ],
        }}
      />
    </Fragment>
  );
};
