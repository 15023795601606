import i18next from 'i18next';

import { P2PProviderType } from 'enums';

const getTypeLabel = (type: P2PProviderType) =>
  i18next.t(`p2p_providers.${type}`, { defaultValue: type });

export const p2pProviderUtils = {
  getTypeLabel,
};
