import i18next from 'i18next';

import { AppConfigKey, AppConfigValueType } from 'enums';

const getKeyLabel = (key: AppConfigKey) =>
  i18next.t(`app_config_key.${key}`, { defaultValue: key });

const getValueField = (valueType: AppConfigValueType) => {
  if (valueType === AppConfigValueType.JsonObject) {
    return 'jsonObjectValue';
  } else if (valueType === AppConfigValueType.JsonArray) {
    return 'jsonArrayValue';
  }
  return '';
};

export const appConfigUtils = {
  getKeyLabel,
  getValueField,
};
