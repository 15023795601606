import axios from 'axios';

import { env } from 'config';
import { OrdersTab, UserRole } from 'enums';
import {
  PayoutOrder,
  PaginatedData,
  QueryParams,
  CreatePayoutOrderDto,
  PayoutOrderDispute,
  PayoutOrderReject,
  ResolvePayoutOrderDispute,
  OrderReceipt,
  ExportOrdersPayload,
  PayoutOrderStatusHistory,
} from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/payout-orders`;
const exportUrl = `${env.dataApiUrl}/payout-orders`;

const getAllPaginatedAsRole =
  (role: UserRole, tab: OrdersTab) =>
  async (params: QueryParams): Promise<PaginatedData<PayoutOrder>> =>
    (await apiUtils.getWithQueryParams(`${url}/${role}/${tab}`, params)).data;

const getPayoutOrderStatusHistoryAsRole =
  (role: UserRole) =>
  async (orderId: string): Promise<PayoutOrderStatusHistory[]> =>
    (await axios.get(`${url}/${role}/${orderId}/status-history`)).data;

const getTraderPayoutOrderExistsByRequisites = async (
  requisitesId: string,
): Promise<{ exists: boolean }> =>
  (await axios.get(`${url}/trader/exists/requisites/${requisitesId}`)).data;

const confirmAsTrader = async (
  order: Partial<PayoutOrder>,
): Promise<PayoutOrder> =>
  (await axios.post(`${url}/trader/${order.id}/confirm`)).data;

const confirmAutomationAsTrader = async ({
  orderId,
  automationId,
}: {
  orderId: string;
  automationId: string;
}): Promise<PayoutOrder> =>
  (
    await axios.post(
      `${url}/trader/${orderId}/confirm-automation/${automationId}`,
    )
  ).data;

const acceptAsTrader = async (
  order: Partial<PayoutOrder>,
): Promise<PayoutOrder> =>
  (await axios.post(`${url}/trader/${order.id}/accept`)).data;

const rejectAsTrader = async ({
  id,
  data,
}: {
  id: string;
  data: PayoutOrderReject;
}): Promise<PayoutOrder> =>
  (await axios.post(`${url}/trader/${id}/reject`, data)).data;

const disputeAsTrader = async ({
  id,
  data,
}: {
  id: string;
  data: PayoutOrderDispute;
}): Promise<PayoutOrder> =>
  (await axios.post(`${url}/trader/${id}/dispute`, data)).data;

const cancelCompletedAsRole =
  (role: UserRole) =>
  async (id: string): Promise<PayoutOrder> =>
    (await axios.post(`${url}/${role}/${id}/cancel-completed`)).data;

const disputeCancelledAsRole =
  (role: UserRole) =>
  async (id: string): Promise<PayoutOrder> =>
    (await axios.post(`${url}/${role}/${id}/dispute-cancelled`)).data;

const resolveDisputeAsRole =
  (role: UserRole) =>
  async ({
    id,
    data,
  }: {
    id: string;
    data: ResolvePayoutOrderDispute;
  }): Promise<PayoutOrder> =>
    (await axios.post(`${url}/${role}/${id}/resolve-dispute`, data)).data;

const uploadReceiptAsRole =
  (role: UserRole) =>
  async ({ orderId, body }: { orderId: string; body: any }) =>
    (await axios.post(`${url}/${role}/${orderId}/receipts`, body)).data;

const generateReceiptUrlAsRole =
  (role: UserRole) =>
  async ({ orderId, body }: { orderId: string; body: OrderReceipt }) =>
    (await axios.post(`${url}/${role}/${orderId}/receipts/url`, body)).data;

const getOrderReceiptsAsRole =
  (role: UserRole) =>
  async (orderId: string): Promise<OrderReceipt[]> =>
    (await axios.get(`${url}/${role}/${orderId}/receipts`)).data;

const removeReceiptAsRole =
  (role: UserRole) =>
  async ({
    orderId,
    body,
  }: {
    orderId: string;
    body: OrderReceipt;
  }): Promise<void> =>
    (await axios.post(`${url}/${role}/${orderId}/remove-receipt`, body)).data;

const createAsRole =
  (role: UserRole) =>
  async (order: CreatePayoutOrderDto): Promise<PayoutOrder> =>
    (await axios.post(`${url}/${role}`, order)).data;

const exportAsRole =
  (role: UserRole) =>
  async ({ data, filters }: { data: ExportOrdersPayload; filters: string }) =>
    await apiUtils.requestDownload(
      {
        url: `${exportUrl}/${role}/export`,
        method: 'post',
        data,
        params: { filters },
      },
      `${data.state}-payout-orders-${Date.now()}.xlsx`,
    );

const getExportColumnsAsRole =
  (role: UserRole) => async (): Promise<{ columns: string[] }> =>
    (await axios.get(`${exportUrl}/${role}/export/columns`)).data;

const refreshAutomationStatusAsRole =
  (role: UserRole) =>
  async (id: string): Promise<PayoutOrder> =>
    (await axios.post(`${url}/${role}/${id}/refresh-automation-status`)).data;

export const payoutOrdersApi = {
  getAllPaginatedAsRole,
  getTraderPayoutOrderExistsByRequisites,
  acceptAsTrader,
  confirmAsTrader,
  confirmAutomationAsTrader,
  rejectAsTrader,
  disputeAsTrader,
  uploadReceiptAsRole,
  removeReceiptAsRole,
  cancelCompletedAsRole,
  disputeCancelledAsRole,
  generateReceiptUrlAsRole,
  getOrderReceiptsAsRole,
  createAsRole,
  resolveDisputeAsRole,
  exportAsRole,
  getExportColumnsAsRole,
  getPayoutOrderStatusHistoryAsRole,
  refreshAutomationStatusAsRole,
};
