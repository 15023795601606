import { Alert } from '@mui/material';
import { AxiosError } from 'axios';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import * as Yup from 'yup';

import { payoutOrdersApi } from 'api';
import {
  CloseFormikDialogResult,
  Dialog,
  FormControls,
  FormikNumericField,
  FormikYesNoRadioGroup,
  CloseDialogHandler,
  FormikCheckbox,
} from 'components';
import { PayoutOrderStatus, QueryKey } from 'enums';
import { useCurrencies, usePrevious, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutOrder, ResolvePayoutOrderDispute } from 'types';
import { validationUtils } from 'utils';

import { ManageReceipts } from '../ManageReceipts';

type Values = {
  amount: number;
  paymentSent: boolean;
  newRequisites: boolean;
};

type Props = {
  open: boolean;
  order: PayoutOrder;
  error?: AxiosError | null;
  disabled?: boolean;
  onClose: CloseDialogHandler<ResolvePayoutOrderDispute>;
};

export const ResolveDisputeDialog: React.FC<Props> = ({
  open,
  order,
  error,
  disabled,
  onClose,
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.dispute',
  });
  const { role } = useUser();
  const prevOpen = usePrevious(open);
  const { getFiatCurrencySymbol } = useCurrencies();
  const [initialValues, setInitialValues] = useState<Values>({
    amount: order.amount,
    paymentSent: true,
    newRequisites: false,
  });

  const queryReceiptsKey = useMemo(
    () => [QueryKey.PayoutOrderReceipts, order?.id],
    [order],
  );
  const queryReceiptsEnabled = useMemo(
    () => open && !prevOpen && !!order?.id && !order.p2pProviderOrderId,
    [order, open, prevOpen],
  );
  const queryResultReceipts = useQuery(
    queryReceiptsKey,
    () => payoutOrdersApi.getOrderReceiptsAsRole(role)(order.id),
    { enabled: queryReceiptsEnabled },
  );

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        amount: Yup.number()
          .moreThan(0, tCommon('errors.natural_number'))
          .required(tCommon('errors.required')),
        paymentSent: Yup.boolean().required(tCommon('errors.required')),
        newRequisites: Yup.boolean().required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  const currencySymbol = useMemo(
    () => getFiatCurrencySymbol(order?.fiatCurrencyId),
    [order, getFiatCurrencySymbol],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      // TODO: reset on success also
      if (!result.ok) {
        result.data?.formikHelpers.resetForm();
      }
      if (result.data?.values) {
        const { values } = result.data;
        onClose({
          ok: result.ok,
          data: {
            amount: values.amount,
            status: values.paymentSent
              ? PayoutOrderStatus.Completed
              : !values.newRequisites
              ? PayoutOrderStatus.Cancelled
              : PayoutOrderStatus.Requisites,
          },
        });
      }
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      handleClose({ ok: true, data: { values, formikHelpers } });
    },
    [handleClose],
  );

  const renderOrderStatus = useCallback(
    (status: string, prefix?: string) => (
      <span>
        {`${prefix || t('resolve_as.prefix')} `}
        <span className="tw-font-bold">{status}</span>
      </span>
    ),
    [t],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const okDisabled = useMemo(() => {
    if (order.orderAutomationId) {
      return false;
    }
    if (formik.values.paymentSent) {
      return !order.p2pProviderOrderId && isEmpty(queryResultReceipts.data);
    }

    return false;
  }, [
    formik.values.paymentSent,
    order.orderAutomationId,
    order.p2pProviderOrderId,
    queryResultReceipts.data,
  ]);

  const renderAlert = useCallback(() => {
    if (formik.values.paymentSent) {
      return renderOrderStatus(t('resolve_as.completed'));
    } else {
      if (formik.values.newRequisites) {
        return renderOrderStatus(
          t('resolve_as.requisites'),
          t('resolve_as.requisites_prefix'),
        );
      } else {
        return renderOrderStatus(t('resolve_as.cancelled'));
      }
    }
  }, [formik, renderOrderStatus, t]);

  useEffect(() => {
    if (open && !prevOpen) {
      setInitialValues({
        amount: order.amount,
        paymentSent: true,
        newRequisites: false,
      });
    }
  }, [open, prevOpen, order]);

  useEffect(() => {
    if (error) {
      formik.setErrors(
        validationUtils.getFormErrors(error, {
          amount: { too_big: tCommon('errors.not_enough_money') },
        }),
      );
    } else {
      formik.setErrors({});
    }
  }, [tCommon, formik, error]);

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        title={t('title')}
        data={{ values: formik.values, formikHelpers: formik }}
        okDisabled={okDisabled}
        onClose={handleClose}
      >
        <Form>
          <Alert severity="info" sx={{ mb: 4, py: 0 }}>
            {renderAlert()}
          </Alert>
          <FormControls>
            <FormikYesNoRadioGroup
              name="paymentSent"
              disabled={disabled}
              label={t('payment_sent')}
            />
            {formik.values.paymentSent && (
              <Fragment>
                <FormikNumericField
                  name="amount"
                  disabled={disabled}
                  label={`${t('received_amount')} (${currencySymbol})`}
                  allowNegative={false}
                  required
                  fullWidth
                />
                {!order?.p2pProviderOrderId && (
                  <ManageReceipts
                    queryResultReceipts={queryResultReceipts}
                    order={order}
                  />
                )}
              </Fragment>
            )}
            {!formik.values.paymentSent && !order?.p2pProviderOrderId && (
              <FormikCheckbox
                name="newRequisites"
                label={t('new_requisites')}
                sx={{ paddingLeft: 0 }}
              />
            )}
          </FormControls>
        </Form>
      </Dialog>
    </FormikProvider>
  );
};
